import { Controller } from "@hotwired/stimulus"

// Controller for the Attachments modal.
// Handles show/hide of the modal
export default class extends Controller {
  static targets = ["modal", "content", "form"];

  initialize() {
    // Show the modal if it exists
    if(document.getElementById('entryModal')) {
      this.open();
    }
  }

  connect() {
    // print message in the modal if there are no attachments
    if(this.hasContentTarget && this.contentTarget.innerHTML.trim() == '') {
      this.contentTarget.innerHTML = "no attachments found";
    }

    // Close modal on Esc keypress
    const esc = (e) => {
      if (e.keyCode == 27) {
        this.close();
      }
    }
    document.addEventListener("keydown", esc)
  }

  open() {
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
  }

  close() {
    this.element.removeAttribute("style");
    this.element.classList.remove("show");

    // un-shade the entry row
    entryList = document.getElementById('entryList')
    entryList.querySelector('.entry_active').classList.remove('entry_active')
  }

  // close modal after form submission
  handleSuccess({ detail: { success } }) {
    if (success) {
      // un-dim this entry's paperclip icon, in case it is going from 0 to >0 attachments
      var entryRow = document.getElementById('entryList').querySelector('.entry_active')
      if(entryRow != null) {
        entryRow.getElementsByTagName('svg')[0].classList.remove('dim')
      }

      // Close attachments modal.
      // (must happen AFTER the icon was located)
      this.formTarget.reset()
      this.close()
    }
  }
}
