// Attach onclick handler to show or hide the admin menu.
// Must run after DOM is loaded.
var load = function(event) {
  const showNavbar = (toggleId, navId, bodyId, headerId) =>{
    const toggle = document.getElementById(toggleId),
      nav = document.getElementById(navId),
      bodypd = document.getElementById(bodyId),
      headerpd = document.getElementById(headerId)

    if(toggle && nav && bodypd && headerpd){
      toggle.addEventListener('click', ()=>{
        // grow / shrink the admin menu 
        nav.classList.toggle('show')

        // change icon of the toggle
        icon = toggle.getElementsByTagName('svg')[0]
        if(nav.classList.contains('show')) {
          icon.classList.replace('fa-angle-double-right', 'fa-angle-double-left');
        } else {
          icon.classList.replace('fa-angle-double-left', 'fa-angle-double-right');
        }

        // add padding to body and header
        bodypd.classList.toggle('body-pd')
        headerpd.classList.toggle('body-pd')

        // ajax request for rails to store admin menu preference in the session
        $.get('/sessions/toggle_admin_menu.json')
      })
    }
  }

  showNavbar('menu-toggle','nav-bar','body-pd','header')

  /*===== LINK ACTIVE =====*/
  const linkColor = document.querySelectorAll('.nav_link')

  function colorLink(){
    if(linkColor){
      linkColor.forEach(l=> l.classList.remove('active'))
      this.classList.add('active')
    }
  }
  linkColor.forEach(l=> l.addEventListener('click', colorLink))
};

// Run function after DOM loads
document.addEventListener("turbo:load", function() { load(); });
