import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "link" ]

  connect() {
    // List what targets we currently have
    this.linkTargets.forEach((element, index) => {
      console.log("Found link target ", element)
    })

    var msg = this.element.textContent;
    console.log("msg is a ", typeof(msg));
    console.log(msg.trim());
    switch(msg.trim()) {
      case "Deleted note":
        this.update_link_text("Add note");
        this.update_modal_target()
        break;
      case "Added note":
        this.update_link_text("Edit note");
        break;
    }
  }

  update_link_text(text) {
    console.log("Update link text to ", text)
    document.getElementById("noteLink").text = text
  }

  update_modal_target(url) {
    console.log("Update modal target to ", url)
    document.getElementById("note-modal").src = url
  }

  item_id() {
    document.baseURI.split('/').slice(-1)[0]
  }
}
