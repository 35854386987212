import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "uploadButton" ]

  // Handler for selecting a file in the file input control
  handleFileFieldChange() {
    this.readUrl()
    this.uploadButtonTarget.disabled = false
  }

  // Upload image file into preview area
  readUrl() {
    const input = document.getElementById('logoFileInput')
    if (input.files && input.files[0]) {
      var reader = new FileReader()

      reader.onload = function (e) {
        console.log("set logo previewImg src attribute")
        previewImg = document.getElementById('logoPreviewImg')
        previewImg.setAttribute('src', e.target.result)
      };
      reader.readAsDataURL(input.files[0])
    }
  }
}
