import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // respond to new text being entered in email textarea
  handleInput() {
    const submitBtn = document.getElementById('form_submit_btn')
    if(this.validateEmail(this.element.value)) {
      submitBtn.disabled = false
      submitBtn.focus()
    } else {
      submitBtn.disabled = true
      alert("Invalid email address")
    }
  }

  // return true if given string is a valid email address
  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    {
      return (true)
    }
    return (false)
  }
}
