import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "delete-button" ]

  delete (event) {
    if(!window.confirm("Are you sure you want to delete this attachment?")) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
