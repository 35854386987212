// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"

// Enables fontawesome free icons. 'fortawesome' is not a typo.
import "@fortawesome/fontawesome-free/js/all"

// Fraser: Custom javascript written for the doors application goes here,
// but only if it should be loaded on every page.
import "./admin_menu"
import 'controllers'

import 'stylesheets/application'

Rails.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")
