import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "uploadButton" ]

  // Handler for selecting a file in the file input control
  handleFileFieldChange() {
    // Enable the upload button
    this.uploadButtonTarget.disabled = false
  }
}
