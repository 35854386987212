import { Application } from "@hotwired/stimulus"

// Drop this line when replacing webpack with import maps
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application
export { application }

// Drop these 2 lines when replacing webpack with import maps
const context = require.context("./", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

